import React from "react";
import { connect } from "react-redux";
import classes from "./Line.module.css";
import ReactApexChart from "react-apexcharts";

function Line({ vertical, name, voltage, current, hp }) {
  const state = {
    options: {
      chart: {
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 4,
            size: "70%",
            background: "#fff",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            strokeWidth: "67%",
            margin: 2,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },

          dataLabels: {
            name: {
              offsetY: 22,
              color: "#6D6D6D",
              fontSize: "12px",
            },
            value: {
              formatter: (_, { config: c }) => parseFloat(c.val),
              offsetY: -14,
              fontSize: "25px",
              fontWeight: "bold",
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
    },
  };
  return (
    <div className={vertical ? classes.vertical : classes.horizontal}>
      <div className={classes.name}>{name}</div>
      <div className={classes.left}>
        <ReactApexChart
          options={{
            ...state.options,
            colors: voltage > 180 && voltage < 250 ? ["#4CAA9C"] : ["#f55c47"],
            labels: ["Volts"],
            val: voltage,
          }}
          series={[voltage > 320 ? 75 : (voltage * 75) / 320]}
          type="radialBar"
        />
      </div>
      <div className={classes.right}>
        <ReactApexChart
          options={{
            ...state.options,
            colors: Math.abs((hp - current) / hp) < 0.25 ? ["#4CAA9C"] : ["#f55c47"],
            labels: ["Amps"],
            val: current,
          }}
          series={[(current * 36) / hp]}
          type="radialBar"
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({ profileReducer: p }) => {
  return {
    hp: p.motorHorsePower,
  };
};

export default connect(mapStateToProps, null)(Line);
