import React, { Component } from "react";
import { signUp } from "../store/actions/userActions";
import { connect } from "react-redux";
import classes from "./LoginPage.module.css";
import { history } from "../history";
import iLogo from "../asset/logo.png";
import { BiLogInCircle } from "react-icons/bi";
import { NotificationManager } from "react-notifications";

class SignupPage extends Component {
  state = {
    Name: "",
    MobileNo: "",
    password: "",
    confirmPassword: "",
    deviceID: "",
    motorhorsePower: "",
  };

  signupHandler = () => {
    if (this.validate()) {
      this.props.signUp(
        this.state.Name,
        this.state.MobileNo,
        this.state.password,
        this.state.deviceID,
        this.state.motorhorsePower
      );
    }
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.signupHandler();
    }
  };

  validate = () => {
    if (this.state.Name.trim().length < 3) {
      NotificationManager.error("Please enter a valid name!");
      return false;
    } else if (!/^\d{10}$/.test(this.state.MobileNo.trim())) {
      NotificationManager.error("Please enter a valid 10 digit mobile number!");
      return false;
    } else if (this.state.password.trim().length < 6) {
      NotificationManager.error("Password length must be 6 or more!");
      return false;
    } else if (this.state.confirmPassword !== this.state.password) {
      NotificationManager.error("Passwords are not matching!");
      return false;
    } else if (this.state.motorhorsePower > 10 || this.state.motorhorsePower < 1) {
      NotificationManager.error("Please enter a valid horsepower between 1 to 10!");
      return false;
    }
    return true;
  };

  render() {
    return (
      <div className={classes.shell}>
        <div className={classes.login}>
          <div className={classes.logo}>
            <img src={iLogo} alt="logo" />
            <div>iStarter</div>
          </div>
          <div>
            <div className={`form-floating mb-3 ${classes.floating}`}>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Name"
                value={this.state.Name}
                onChange={(e) => this.setState({ Name: e.target.value })}
                onKeyDown={(e) => this.onKeyPress(e, "")}
                required
                autoFocus
              />
              <label htmlFor="name">Name</label>
            </div>
            <div className={`form-floating mb-3 ${classes.floating}`}>
              <input
                type="text"
                className="form-control"
                id="device"
                placeholder="Device ID"
                value={this.state.deviceID}
                onChange={(e) => this.setState({ deviceID: e.target.value })}
                onKeyDown={(e) => this.onKeyPress(e, "")}
                required
              />
              <label htmlFor="device">Device ID</label>
            </div>
            <div className={`form-floating mb-3 ${classes.floating}`}>
              <input
                type="text"
                className="form-control"
                id="mobile"
                placeholder="Mobile Number"
                value={this.state.MobileNo}
                onChange={(e) => this.setState({ MobileNo: e.target.value })}
                onKeyDown={(e) => this.onKeyPress(e, "")}
                required
              />
              <label htmlFor="mobile">Mobile Number</label>
            </div>
            <div className={`form-floating mb-3 ${classes.floating}`}>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Password"
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
                onKeyDown={(e) => this.onKeyPress(e, "password")}
                required
              />
              <label htmlFor="password">Password</label>
            </div>
            <div className={`form-floating mb-3 ${classes.floating}`}>
              <input
                type="password"
                className="form-control"
                id="cpassword"
                placeholder="Confirm Password"
                value={this.state.confirmPassword}
                onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                onKeyDown={(e) => this.onKeyPress(e, "")}
                required
              />
              <label htmlFor="cpassword">Confirm Password</label>
            </div>
            <div className={`form-floating mb-3 ${classes.floating}`}>
              <input
                type="number"
                className="form-control"
                id="hp"
                placeholder="Motor Horsepower 1-10"
                value={this.state.motorhorsePower}
                onChange={(e) => this.setState({ motorhorsePower: e.target.value })}
                onKeyDown={(e) => this.onKeyPress(e, "")}
                required
              />
              <label htmlFor="hp">Motor Horsepower 1-10</label>
            </div>
            <div
              className={`${classes.loginButton} ${
                this.props.authReducer.loading ? classes.loginProcess : ""
              }`}
              onClick={this.props.authReducer.loading ? () => {} : this.signupHandler}
            >
              <BiLogInCircle />
              &nbsp;&nbsp;SignUp
            </div>
            <div className={classes.signupline}>Already have an account?</div>
            <div className={classes.signup} onClick={() => history.push("/")}>
              Login here
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (Name, MobileNo, password, deviceID, motorhorsePower) =>
      dispatch(signUp(Name, MobileNo, password, deviceID, motorhorsePower)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);
