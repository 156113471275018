import axios from 'axios';
import { getStore } from './index';

class AxiosConfig {
  static init() {
    axios.interceptors.request.use(config => {
      const token = getStore().getState().authReducer.accessToken;
      if (token) {
        config.headers.common.Authorization = `Bearer ${token}`;
      }

      return config;
    });

    axios.interceptors.request.use(null, error => {
      return Promise.reject(error);
    });
  }
}

export default AxiosConfig;