import React, { Component } from "react";
import { connect } from "react-redux";
import Swipe from "react-easy-swipe";

import { getReadings, saveSettings, startMotor, stopMotor } from "../../store/actions/userActions";
import classes from "./Dashboard.module.css";
import iSignal0 from "../../asset/signal0.png";
import iSignal1 from "../../asset/signal1.png";
import iSignal2 from "../../asset/signal2.png";
import iSignal3 from "../../asset/signal3.png";
import iSignal4 from "../../asset/signal4.png";
import iBattery0 from "../../asset/battery0.png";
import iBattery1 from "../../asset/battery1.png";
import iBattery2 from "../../asset/battery2.png";
import iSchedule from "../../asset/schedule.png";
import iPower from "../../asset/power.png";
import loader from "../../asset/loader.gif";

import Navbar from "../Navbar/Navbar";
import Line from "../Line/Line";
import Scheduler from "./Scheduler";

class Dashboard extends Component {
  state = { schedulePanel: false, counter: 0 };

  reload = () => {
    if (!this.props.loading) {
      this.props.getReadings();
      this.setState((s) => ({
        counter: s.counter + 1,
      }));
    }
  };

  setReload = (timer) => {
    this.setState({ counter: 0 });
    clearInterval(this.myTimer);
    this.myTimer = setInterval(() => {
      this.reload();
      if (this.state.counter === 12) this.setReload(30000);
      else if (this.state.counter === 6 && timer === 30000) clearInterval(this.myTimer);
    }, timer || 10000);
  };

  componentDidMount() {
    if (this.props.token) {
      this.props.getReadings();
      this.setReload();
    }
  }

  componentWillUnmount() {
    clearInterval(this.myTimer);
  }

  toHHMMSS = (sec_num) => {
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;
    var am = hours >= 12 ? "PM" : "AM";
    hours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;

    return (
      hours.toString().padStart(2, "0") +
      ":" +
      minutes.toString().padStart(2, "0") +
      ":" +
      seconds.toString().padStart(2, "0") +
      " " +
      am
    );
  };

  swipeRight = () => {
    if (!this.props.motorUpdating)
      if (this.props.motorStatus) this.props.stopMotor();
      else this.props.startMotor();

    this.setState(() => ({ counter: 0 }));
  };

  schedule = () => this.setState({ schedulePanel: true, counter: 0 });

  render() {
    return (
      <div className={classes.full}>
        <Navbar onReading={this.setReload} />
        <div className={classes.body}>
          <div className={classes.statusBar}>
            <div>
              <div className={classes.title}>Report Time</div>
              <div className={`${classes.text} ${this.props.stale && classes.stale} `}>
                {this.toHHMMSS(this.props.reportTime)}
              </div>
            </div>
            <div>
              <div className={classes.title}>Operator</div>
              <div className={classes.text}>{this.props.operator}</div>
            </div>
            <div className={classes.signal}>
              <img
                className={classes.icon}
                src={
                  this.props.signal > 19
                    ? iSignal4
                    : this.props.signal > 14
                    ? iSignal3
                    : this.props.signal > 9
                    ? iSignal2
                    : this.props.signal > 1
                    ? iSignal1
                    : iSignal0
                }
                alt="Signal"
              />
              <div>
                <div className={classes.title}>Signal</div>
                <div className={classes.text}>
                  {this.props.signal > 19
                    ? "Excellent"
                    : this.props.signal > 14
                    ? "Good"
                    : this.props.signal > 9
                    ? "Ok"
                    : this.props.signal > 1
                    ? "Poor"
                    : "No signal"}
                </div>
              </div>
            </div>
            <div className={classes.signal}>
              <img
                className={classes.icon}
                src={
                  this.props.battery > 90
                    ? iBattery2
                    : this.props.battery > 30
                    ? iBattery1
                    : iBattery0
                }
                alt="Signal"
              />
              <div>
                <div className={classes.title}>Battery</div>
                <div className={classes.text}>{this.props.battery}%</div>
              </div>
            </div>
          </div>
          <div className={classes.main}>
            <Line
              vertical={this.props.motorType === 1}
              name={this.props.motorType === 3 ? "Line1" : ""}
              voltage={this.props.v1}
              current={this.props.c1}
            />
            {this.props.motorType === 3 && (
              <>
                <Line name="Line2" voltage={this.props.v2} current={this.props.c2} />
                <Line name="Line3" voltage={this.props.v3} current={this.props.c3} />
              </>
            )}
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.scheduleButton} onClick={this.schedule}>
            <img src={iSchedule} alt="Schedule" />
          </div>
          <Swipe
            className={`${classes.onOffButton} ${
              this.props.motorUpdating || !this.props.online
                ? classes.motorUpdating
                : !this.props.motorStatus
                ? classes.motorOff
                : ""
            }`}
            onSwipeRight={this.swipeRight}
          >
            {this.props.online && (
              <>
                <img src={this.props.motorUpdating ? loader : iPower} alt="Power" />
                &nbsp;&nbsp;&nbsp;
              </>
            )}
            {this.props.online
              ? (this.props.motorUpdating ? "SWITCHING MOTOR " : "SWIPE TO SWITCH ") +
                (this.props.motorStatus ? "OFF" : "ON")
              : "DEVICE IS OFFLINE"}
          </Swipe>
          {this.state.schedulePanel && (
            <Scheduler
              schedule={this.props.schedule}
              startTime={this.props.startTime}
              stopTime={this.props.stopTime}
              onCancel={() => this.setState({ schedulePanel: false })}
              onOk={this.props.saveSettings}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  authReducer: a,
  profileReducer: p,
  readingsReducer: r,
  scheduleReducer: s,
}) => {
  const startHour = "" + Math.floor(s.startTime / 3600);
  const startMin = "" + Math.floor((s.startTime - startHour * 3600) / 60);
  const stopHour = "" + Math.floor(s.stopTime / 3600);
  const stopMin = "" + Math.floor((s.stopTime - stopHour * 3600) / 60);

  return {
    token: a.accessToken,
    reportTime: p.reportTime,
    operator: p.operator,
    signal: p.signal,
    battery: p.battery,
    motorType: p.motorType,
    online: p.status,
    v1: r.v1,
    c1: r.c1,
    v2: r.v2,
    c2: r.c2,
    v3: r.v3,
    c3: r.c3,
    vertical: !r.v2 && !r.c2 && !r.v3 && !r.c3,
    loading: r.loading,
    motorStatus: r.c1 > 0.1 && (p.motorType === 1 || (r.c2 > 0.1 && r.c3 > 0.1)),
    motorUpdating: r.motorUpdating,
    schedule: s.setting,
    startTime: startHour.padStart(2, 0) + ":" + startMin.padStart(2, 0),
    stopTime: stopHour.padStart(2, 0) + ":" + stopMin.padStart(2, 0),
    stale:
      new Date().getHours() * 3600 +
        new Date().getMinutes() * 60 +
        new Date().getSeconds() -
        p.reportTime >
      60,
  };
};

const mapDispatchToProps = {
  getReadings,
  saveSettings,
  startMotor,
  stopMotor,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
