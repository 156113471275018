import { handleActions } from "redux-actions";

const defaultState = {
  name: null,
  MobileNo: null,
  email: null,
  device: null,
  address: null,
  deviceStatus: null,
  motorHorsePower: null,
  motorType: null,
  bgColor: "#FE5F55", //offline
  status: true,
  reportTime: null,
  operator: "Unknown",
  signal: 0,
  battery: 0,
};

export default handleActions(
  {
    USER_PROFILE_SUCCESS: (
      state,
      { payload: { name, MobileNo, email, device, address, motorHorsePower, motorType } }
    ) => {
      return {
        ...state,
        name,
        MobileNo,
        email,
        device,
        address,
        motorHorsePower,
        motorType,
      };
    },

    DEVICE_STATUS_SUCCESS: (
      state,
      { payload: { deviceStatus, reportTime, operator, signal, battery } }
    ) => {
      if (operator.match(new RegExp(/[^a-zA-Z0-9]+/g))) operator = "[Airtel]";
      if (reportTime > state.reportTime) {
        return {
          ...state,
          deviceStatus,
          reportTime,
          operator,
          signal,
          battery,
        };
      } else {
        return {
          ...state,
          deviceStatus,
        };
      }
    },

    GET_READINGS_SUCCESS: (
      state,
      {
        payload: {
          Data: { reportTime, operator, signal, battery },
        },
      }
    ) => {
      return {
        ...state,
        reportTime,
        operator,
        signal,
        battery,
      };
    },

    CHECK_DEVICE_STATUS: (state) => {
      return {
        ...state,
      };
    },

    DEVICE_STATUS_CONNECTED: (state) => {
      return {
        ...state,
        bgColor: "#6CAE75", //online
        status: true,
      };
    },

    DEVICE_STATUS_DISCONNECTED: (state) => {
      return {
        ...state,
        bgColor: "#FE5F55", //offline
        status: false,
      };
    },

    LOGOUT_USER_SUCCESS: (state) => {
      return {
        ...defaultState,
      };
    },

    UPDATE_PROFILE_SUCCESS: (
      state,
      { payload: { name, address, motorHorsePower, email, deviceID } }
    ) => {
      return {
        ...state,
        name: name,
        email: email,
        device: deviceID,
        address: address,
        motorHorsePower: motorHorsePower,
      };
    },
  },
  defaultState
);
