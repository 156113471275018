import React, { Component } from "react";
import { forgotPassword } from "../store/actions/userActions";
import { connect } from "react-redux";
import classes from "./LoginPage.module.css";
import iLogo from "../asset/logo.png";
import { BiLogInCircle } from "react-icons/bi";
import { history } from "../history";

class ForgotPassword extends Component {
  state = {
    mobileNumber: "",
  };

  submitHandler = () => {
    if (this.state.mobileNumber.trim()) this.props.forgotPassword(this.state.mobileNumber);
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.submitHandler();
    }
  };

  render() {
    return (
      <div className={`${classes.shell} ${classes.flex}`}>
        <div className={classes.login}>
          <div className={classes.logo}>
            <img src={iLogo} alt="logo" />
            <div>iStarter</div>
          </div>
          <div>
            <div className={classes.header}>Forgot Password</div>
            <div className={`form-floating mb-3 ${classes.floating}`}>
              <input
                type="text"
                className="form-control"
                id="mobile"
                placeholder="Mobile Number"
                value={this.state.mobileNumber}
                onChange={(e) => this.setState({ mobileNumber: e.target.value })}
                onKeyDown={(e) => this.onKeyPress(e)}
                required
                autoFocus
              />
              <label htmlFor="mobile">Mobile Number</label>
            </div>
            <div
              className={`${classes.loginButton} ${this.props.loading ? classes.loginProcess : ""}`}
              onClick={this.props.loading ? () => {} : this.submitHandler}
            >
              <BiLogInCircle />
              &nbsp;&nbsp;SUBMIT
            </div>
            <div className={classes.signupline}>Already have a password?</div>
            <div className={classes.signup} onClick={() => history.push("/")}>
              Login here
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authReducer: { loading } }) => {
  return { loading };
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (mobileNumber) => dispatch(forgotPassword(mobileNumber)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
