import React, { Component } from "react";
import { login, resetLogin } from "../store/actions/userActions";
import { connect } from "react-redux";
import classes from "./LoginPage.module.css";
import iLogo from "../asset/logo.png";
import { BiLogInCircle } from "react-icons/bi";
import { history } from "../history";
import { NotificationManager } from "react-notifications";

class LoginPage extends Component {
  state = {
    MobileNumber: "",
    password: "",
    rememberMe: false,
    loggedIn: false,
  };

  componentDidMount() {
    this.props.resetLogin();
  }

  loginHandler = async () => {
    if (!/^\d{10}$/.test(this.state.MobileNumber.trim()))
      NotificationManager.error("Please enter a valid 10 digit mobile number!");
    else if (this.state.password.trim() === "")
      NotificationManager.error("Password cannot be empty!");
    else this.props.login(this.state.MobileNumber, this.state.password);
  };

  onKeyPress = (e, type) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.loginHandler();
    }
  };

  render() {
    return (
      <div className={`${classes.shell} ${classes.flex}`}>
        <div className={classes.login}>
          <div className={classes.logo}>
            <img src={iLogo} alt="logo" />
            <div>iStarter</div>
          </div>
          <div>
            <div className={`form-floating mb-3 ${classes.floating}`}>
              <input
                type="text"
                className="form-control"
                id="mobile"
                placeholder="Mobile Number"
                value={this.state.MobileNumber}
                onChange={(e) => this.setState({ MobileNumber: e.target.value })}
                onKeyDown={(e) => this.onKeyPress(e, "")}
                required
                autoFocus
              />
              <label htmlFor="mobile">Mobile Number</label>
            </div>
            <div className={`form-floating mb-3 ${classes.floating}`}>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Password"
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
                onKeyDown={(e) => this.onKeyPress(e, "password")}
                required
              />
              <label htmlFor="password">Password</label>
            </div>
            <div className={classes.forgot} onClick={() => history.push("/forgotPassword")}>
              Forgot Password?
            </div>
            <div
              className={`${classes.loginButton} ${this.props.loading ? classes.loginProcess : ""}`}
              onClick={this.props.loading ? () => {} : this.loginHandler}
            >
              <BiLogInCircle />
              &nbsp;&nbsp;LOGIN
            </div>
            <div className={classes.signupline}>Don’t have an account yet?</div>
            <div className={classes.signup} onClick={() => history.push("/signup")}>
              SignUp here
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authReducer: { loading } }) => {
  return { loading };
};

const mapDispatchToProps = {
  resetLogin,
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
