import React from "react";

import { FaCheck, FaTimes } from "react-icons/fa";

import classes from "./BottomBar.module.css";
const BottomBar = ({ children, onCancel, onOk }) => (
  <>
    <div className={classes.main} onClick={() => onCancel()}></div>
    <div className={classes.bottom}>
      <div className={classes.action}>
        <div className={classes.cancel} onClick={() => onCancel()}>
          <FaTimes />
        </div>
        <div className={classes.ok} onClick={onOk}>
          <FaCheck />
        </div>
      </div>
      {children}
    </div>
  </>
);

export default BottomBar;
