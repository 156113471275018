import React, { Component } from "react";
import { connect } from "react-redux";
import { resetPassword } from "../store/actions/userActions";
import classes from "./LoginPage.module.css";
import iLogo from "../asset/logo.png";
import { BiLogInCircle } from "react-icons/bi";
import { history } from "../history";

class ForgotPassword extends Component {
  state = {
    otp: "",
    newPassword: "",
    confirmPassword: "",
    error: "",
  };

  submitHandler = () => {
    this.validate();
    if (this.state.error === "")
      this.props.resetPassword(this.props.mobileNumber, this.state.confirmPassword, this.state.otp);
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.submitHandler();
    }
  };

  validate = () => {
    if (this.state.otp.trim().length < 6) this.setState({ error: "Please enter a valid otp" });
    else if (this.state.newPassword.trim().length < 6)
      this.setState({ error: "Password length must be 6 or more" });
    else if (this.state.confirmPassword !== this.state.newPassword)
      this.setState({ error: "Passwords are not matching" });
    else this.setState({ error: "" });
  };

  render() {
    return (
      <div className={`${classes.shell} ${classes.flex}`}>
        <div className={classes.login}>
          <div className={classes.logo}>
            <img src={iLogo} alt="logo" />
            <div>iStarter</div>
          </div>
          <div>
            <div className={classes.header}>Reset Password</div>
            <div className="row">
              <div className={`col-6 ${classes.mobile}`}>Mobile Number:</div>
              <div className={`col-6 ${classes.number}`}>{this.props.mobileNumber}</div>
            </div>
            <div className={`form-floating mb-3 ${classes.floating}`}>
              <input
                type="text"
                className="form-control"
                id="otp"
                placeholder="OTP"
                value={this.state.otp}
                onChange={(e) => this.setState({ otp: e.target.value })}
                onKeyDown={(e) => this.onKeyPress(e, "")}
                required
                autoFocus
              />
              <label htmlFor="otp">OTP</label>
            </div>
            <div className={`form-floating mb-3 ${classes.floating}`}>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Password"
                value={this.state.newPassword}
                onChange={(e) => this.setState({ newPassword: e.target.value })}
                onKeyDown={(e) => this.onKeyPress(e, "password")}
                required
              />
              <label htmlFor="password">Password</label>
            </div>
            <div className={`form-floating mb-3 ${classes.floating}`}>
              <input
                type="password"
                className="form-control"
                id="cpassword"
                placeholder="Confirm Password"
                value={this.state.confirmPassword}
                onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                onKeyDown={(e) => this.onKeyPress(e, "")}
                required
              />
              <label htmlFor="cpassword">Confirm Password</label>
            </div>
            <div className={classes.error}>{this.state.error}</div>
            <div
              className={`${classes.loginButton} ${this.props.loading ? classes.loginProcess : ""}`}
              onClick={this.props.loading ? () => {} : this.submitHandler}
            >
              <BiLogInCircle />
              &nbsp;&nbsp;SUBMIT
            </div>
            <div className={classes.signupline}>Already have a password?</div>
            <div className={classes.signup} onClick={() => history.push("/")}>
              Login here
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authReducer: { loading, mobileNumber } }) => {
  return { loading, mobileNumber };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (mobileNumber, password, otp) =>
      dispatch(resetPassword(mobileNumber, password, otp)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
