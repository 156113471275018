import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import RootSaga from "./sagas/index";
import persistState from "redux-localstorage";
import authReducer from "./store/reducers/authReducer";
import profileReducer from "./store/reducers/profileReducer";
import readingsReducer from "./store/reducers/readingsReducer";
import scheduleReducer from "./store/reducers/scheduleReducer";
import "bootstrap/dist/css/bootstrap.css";

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  authReducer,
  profileReducer,
  readingsReducer,
  scheduleReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware), persistState())
);

sagaMiddleware.run(RootSaga);

export function getStore() {
  return store;
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
