import { handleActions } from "redux-actions";

const defaultState = {
  email: null,
  loading: false,
  accessToken: null,
  refreshToken: null,
  mobileNumber: "",
};

export default handleActions(
  {
    RESET_LOGIN: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    LOGIN_USER_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    LOGIN_USER_SUCCESS: (state, { payload: { accessToken, refreshToken } }) => {
      return {
        ...state,
        loading: false,
        accessToken,
        refreshToken,
      };
    },

    LOGIN_USER_FAILURE: (state) => {
      return {
        ...state,
        loading: false,
        accessToken: null,
        refreshToken: null,
      };
    },

    LOGOUT_USER_SUCCESS: () => {
      return {
        ...defaultState,
      };
    },

    SIGNUP_USER_REQUEST: (state) => {
      return { ...state, loading: true };
    },

    SIGNUP_USER_SUCCESS: (state) => {
      return { ...state, loading: false };
    },

    SIGNUP_USER_FAILURE: (state) => {
      return { ...state, loading: false };
    },

    FORGOT_PASSWORD_REQUEST: (state) => {
      return { ...state, loading: true };
    },

    FORGOT_PASSWORD_SUCCESS: (state, { payload: { mobileNumber } }) => {
      return { ...state, mobileNumber, loading: false };
    },

    FORGOT_PASSWORD_FAILURE: (state) => {
      return { ...state, loading: false };
    },

    RESET_PASSWORD_REQUEST: (state) => {
      return { ...state, loading: true };
    },

    RESET_PASSWORD_SUCCESS: (state) => {
      return { ...state, loading: false };
    },

    RESET_PASSWORD_FAILURE: (state) => {
      return { ...state, loading: false };
    },

    REFRESH_TOKEN_SUCCESS: (state, { payload: { accessToken } }) => ({
      ...state,
      accessToken,
    }),
  },
  defaultState
);
