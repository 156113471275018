import React, { useEffect, useState } from "react";
import { getReadings } from "../../store/actions/userActions";
import "./Navbar.css";
import logo from "../../asset/logo.png";
import settings from "../../asset/settings.png";
import reload from "../../asset/reload.png";
import loader from "../../asset/loader.gif";
import { connect } from "react-redux";
import { history } from "../../history";
import { BsCloudSlashFill } from "react-icons/bs";

const Navbar = ({ name, online, motorStatus, loading, getReadings, onReading }) => {
  const [offline, setOffline] = useState(false);
  const setStatusOff = () => setOffline(true);
  const setStatusOn = () => setOffline(false);

  useEffect(() => {
    window.navigator.onLine ? setStatusOn() : setStatusOff();

    window.addEventListener("online", setStatusOn);
    window.addEventListener("offline", setStatusOff);

    return () => {
      window.removeEventListener("online", setStatusOn);
      window.removeEventListener("offline", setStatusOff);
    };
  }, []);

  return (
    <div className="main">
      {offline && (
        <div className="internet-status">
          <BsCloudSlashFill />
          &nbsp; No internet connection
        </div>
      )}
      <div className="main-content">
        <div className="left">
          <img className="logo" src={logo} alt="logo" onClick={() => history.push("/")} />
          <div className="text">
            <div className="greeting">Hello {name && name.split(" ")[0]},</div>
            <div className="status-message">
              {online ? (
                <>
                  Motor is&nbsp;<div className="status">{motorStatus ? "ON" : "OFF"}</div>
                </>
              ) : (
                <>
                  Device is&nbsp;<div className="status">OFFLINE</div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="settings" onClick={() => history.push("/profile")}>
          <img className="button" src={settings} alt="settings" />
        </div>
        <div
          className="right"
          onClick={() => {
            getReadings();
            onReading && onReading();
          }}
        >
          <img className="button" src={loading ? loader : reload} alt="reload" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    name: state.profileReducer.name,
    online: state.profileReducer.status,
    motorStatus:
      state.readingsReducer.c1 > 0.1 &&
      (state.profileReducer.motorType === 1 ||
        (state.readingsReducer.c2 > 0.1 && state.readingsReducer.c3 > 0.1)),
    loading: state.readingsReducer.loading,
  };
};

const mapDispatchToProps = {
  getReadings,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
