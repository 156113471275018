import { all, takeEvery, call, put, take, race } from "redux-saga/effects";
import * as types from "../store/actions/actionTypes";
import * as api from "../api/api";
import { history } from "../history";
import { getStore } from "../index";
import { NotificationManager } from "react-notifications";

export function* loginUserSaga({ payload: { MobileNumber, password } }) {
  const params = {
    username: MobileNumber,
    password,
  };

  if (MobileNumber === "" && password === "") {
    yield put({ type: types.LOGIN_USER_FAILURE, payload: { error: null } });
    return;
  }
  try {
    const resData = yield call(api.doLogin, params);
    if (resData.accessToken) {
      yield put({
        type: types.LOGIN_USER_SUCCESS,
        payload: {
          accessToken: resData.accessToken,
          refreshToken: resData.refreshToken,
        },
      });

      yield put({ type: types.USER_PROFILE_REQUEST, payload: {} });

      history.push("/");
    } else {
      yield put({
        type: types.LOGIN_USER_FAILURE,
        payload: null,
      });
      NotificationManager.error(resData.message);
    }
  } catch (err) {
    if (err.response && err.response.data) {
      yield put({
        type: types.LOGIN_USER_FAILURE,
        payload: null,
      });
      NotificationManager.error(err.response.data.message);
    } else {
      yield put({
        type: types.LOGIN_USER_FAILURE,
        payload: null,
      });
      NotificationManager.error("Login failed");
    }
  }
}

export function* refreshTokenSaga() {
  try {
    // eslint-disable-next-line no-shadow
    const { refreshToken } = getStore().getState().authReducer;
    const { accessToken } = yield call(api.doRefreshToken, refreshToken);

    yield all([
      yield put({
        type: types.REFRESH_TOKEN_SUCCESS,
        payload: { accessToken },
      }),
    ]);
  } catch (error) {
    //   yield all([ yield put({ type: types.RESET_APP_REQUEST, payload: {} }) ]);
    yield all([yield put({ type: types.LOGOUT_USER_SUCCESS, payload: {} })]);
    history.push("/");
  }
}

export function* forgotPasswordSaga({ payload }) {
  try {
    const params = { username: payload.mobileNumber };
    const resData = yield call(api.doForgotPassword, params);

    if (resData.success === "SMS Sent") {
      yield put({
        type: types.FORGOT_PASSWORD_SUCCESS,
        payload: { mobileNumber: payload.mobileNumber },
      });
      NotificationManager.success("OTP sent successfully!");
      history.push("/resetPassword");
    } else {
      yield put({
        type: types.FORGOT_PASSWORD_FAILURE,
        payload: { error: "Could not send OTP, please try again" },
      });
      NotificationManager.error("Could not send OTP!");
    }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      yield put({ type: types.REFRESH_TOKEN_REQUEST, payload: {} });
      const { success } = yield race({
        success: take(types.REFRESH_TOKEN_SUCCESS),
        fail: take(types.REFRESH_TOKEN_FAILURE),
      });
      if (success) {
        yield put({
          type: types.FORGOT_PASSWORD_REQUEST,
          payload,
        });
      } else {
        yield put({
          type: types.FORGOT_PASSWORD_FAILURE,
          payload: JSON.stringify(err),
        });
        NotificationManager.error("Could not send OTP!");
      }
    } else {
      yield put({
        type: types.FORGOT_PASSWORD_FAILURE,
        payload: null,
      });
      NotificationManager.error(err.response.data.error);
    }
  }
}

export function* resetPasswordSaga({ payload }) {
  try {
    const params = {
      username: payload.mobileNumber,
      password: payload.password,
      token: payload.otp,
    };
    const resData = yield call(api.doResetPassword, params);

    if (resData[0].success === "Password updated successfully") {
      yield put({
        type: types.RESET_PASSWORD_SUCCESS,
        payload: null,
      });
      NotificationManager.success(resData[0].success);
      history.push("/");
    } else {
      yield put({
        type: types.RESET_PASSWORD_FAILURE,
        payload: { error: "Error occured, please try again" },
      });
      NotificationManager.error(resData[0].error);
    }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      yield put({ type: types.REFRESH_TOKEN_REQUEST, payload: {} });
      const { success } = yield race({
        success: take(types.REFRESH_TOKEN_SUCCESS),
        fail: take(types.REFRESH_TOKEN_FAILURE),
      });
      if (success) {
        yield put({
          type: types.RESET_PASSWORD_REQUEST,
          payload,
        });
      } else {
        yield put({
          type: types.RESET_PASSWORD_FAILURE,
          payload: JSON.stringify(err),
        });
        NotificationManager.error("Error occured!");
      }
    } else {
      yield put({
        type: types.RESET_PASSWORD_FAILURE,
        payload: null,
      });
      NotificationManager.error(err.response.data.error);
    }
  }
}

export function* saveSettingsSaga({ payload: { setting, startTime, stopTime } }) {
  try {
    const { device } = getStore().getState().profileReducer;

    const params = {
      method: "autoStartSetting",
      device,
      setting,
      startTime,
      stopTime,
    };

    const resData = yield call(api.doSaveSettings, params);
    if (resData.result.status === 200) {
      yield put({
        type: types.SAVE_SETTINGS_SUCCESS,
        payload: { setting, startTime, stopTime },
      });
      NotificationManager.success("Settings saved successfully!");
    } else {
      yield put({
        type: types.SAVE_SETTINGS_FAILURE,
        payload: { error: "Save auto start settings failed" },
      });
      NotificationManager.error("Settings failed!");
    }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      yield put({ type: types.REFRESH_TOKEN_REQUEST, payload: {} });
      const { success } = yield race({
        success: take(types.REFRESH_TOKEN_SUCCESS),
        fail: take(types.REFRESH_TOKEN_FAILURE),
      });
      if (success) {
        yield put({
          type: types.SAVE_SETTINGS_REQUEST,
          payload: { setting, startTime, stopTime },
        });
      } else {
        yield put({
          type: types.SAVE_SETTINGS_FAILURE,
          payload: JSON.stringify(err),
        });
        NotificationManager.error("Settings failed!");
      }
    } else {
      yield put({
        type: types.SAVE_SETTINGS_FAILURE,
        payload: JSON.stringify(err),
      });
      NotificationManager.error("Settings failed!");
    }
  }
}

export function* startMotorSaga() {
  try {
    const { device } = getStore().getState().profileReducer;

    const params = {
      method: "start",
      device: device,
    };

    const resData = yield call(api.doStartMotor, params);
    if (resData.result.status && resData.result.status === 200) {
      const Data = resData.result.payload;
      yield put({ type: types.START_MOTOR_SUCCESS, payload: { Data } });
    } else {
      yield put({
        type: types.START_MOTOR_FAILURE,
        payload: { error: "Motor Start failed" },
      });
    }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      yield put({ type: types.REFRESH_TOKEN_REQUEST, payload: {} });

      const { success } = yield race({
        success: take(types.REFRESH_TOKEN_SUCCESS),
        fail: take(types.REFRESH_TOKEN_FAILURE),
      });

      if (success) {
        yield put({ type: types.START_MOTOR, payload: {} });
      }

      //  yield call(delay, 3000);
      //  yield put({ type: types.START_MOTOR, payload: {} });

      // yield put({ type: types.START_MOTOR_FAILURE, payload: { code: 401 } });
    } else {
      yield put({ type: types.CHECK_DEVICE_STATUS, payload: {} });
      yield put({
        type: types.START_MOTOR_FAILURE,
        payload: JSON.stringify(err),
      });
    }
  }
}

export function* userProfileSaga() {
  try {
    // let {accessToken} = getStore().getState().authReducer;
    const resData = yield call(api.doUserProfile);
    yield put({
      type: types.USER_PROFILE_SUCCESS,
      payload: {
        name: resData[0].name,
        MobileNo: resData[0].username,
        email: resData[0].email,
        device: resData[0].device,
        address: resData[0].address,
        motorType: resData[0].motortype,
        motorHorsePower: resData[0].motorhorsepower,
      },
    });
    if (resData[0].autostartsetting) {
      yield put({
        type: types.SAVE_SETTINGS_SUCCESS,
        payload: JSON.parse(resData[0].autostartsetting),
      });
    }

    yield put({ type: types.CHECK_DEVICE_STATUS, payload: {} });
    yield put({ type: types.GET_READINGS_REQUEST, payload: {} });
  } catch (err) {
    yield put({ type: types.USER_PROFILE_FAILURE, payload: { err } });
  }
}

export function* stopMotorSaga() {
  try {
    const { device } = getStore().getState().profileReducer;
    const params = {
      method: "stop",
      device: device,
    };

    const resData = yield call(api.doStopMotor, params);
    if (resData.result.status && resData.result.status === 200) {
      const Data = resData.result.payload;
      yield put({ type: types.STOP_MOTOR_SUCCESS, payload: { Data } });
    } else {
      yield put({
        type: types.STOP_MOTOR_FAILURE,
        payload: { error: "Motor Stop failed" },
      });
    }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      yield put({ type: types.REFRESH_TOKEN_REQUEST, payload: {} });
      // yield call(delay, 2000);
      // yield put({ type: types.STOP_MOTOR, payload: {} });

      const { success } = yield race({
        success: take(types.REFRESH_TOKEN_SUCCESS),
        fail: take(types.REFRESH_TOKEN_FAILURE),
      });

      if (success) {
        yield put({ type: types.STOP_MOTOR, payload: {} });
      }

      // yield put({ type: types.STOP_MOTOR_FAILURE, payload: { code: 401 } });
    } else {
      yield put({ type: types.CHECK_DEVICE_STATUS, payload: {} });

      yield put({
        type: types.STOP_MOTOR_FAILURE,
        payload: JSON.stringify(err),
      });
    }
  }
}

export function* deviceStatusSaga() {
  try {
    const { device } = getStore().getState().profileReducer;
    const params = {
      device: device,
    };

    const resData = yield call(api.doDeviceStatus, params);
    yield put({
      type: types.DEVICE_STATUS_SUCCESS,
      payload: {
        deviceStatus: resData.connectionState,
        reportTime: resData.reportTime,
        operator: resData.operator,
        signal: resData.signal,
        battery: parseInt(resData.battery),
      },
    });
    if (resData.connectionState === "Connected") {
      yield put({ type: types.DEVICE_STATUS_CONNECTED, payload: {} });
    } else {
      yield put({ type: types.DEVICE_STATUS_DISCONNECTED, payload: {} });
      yield put({ type: types.RESET_READING, payload: {} });
    }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      yield put({ type: types.REFRESH_TOKEN_REQUEST, payload: {} });

      const { success } = yield race({
        success: take(types.REFRESH_TOKEN_SUCCESS),
        fail: take(types.REFRESH_TOKEN_FAILURE),
      });

      if (success) {
        yield put({ type: types.CHECK_DEVICE_STATUS, payload: {} });
      }
    } else {
      yield put({
        type: types.DEVICE_STATUS_FAILURE,
        payload: JSON.stringify(err),
      });
    }
  }
}

export function* signUpSaga({ payload: { Name, MobileNo, password, deviceID, motorhorsePower } }) {
  const params = {
    name: Name,
    username: MobileNo,
    password: password,
    deviceid: deviceID,
    motorhorsepower: motorhorsePower,
  };

  if (Name === "" && password === "") {
    yield put({ type: types.SIGNUP_USER_FAILURE, payload: { error: null } });
    return;
  }
  try {
    const resData = yield call(api.doSignUp, params);

    if (resData.success === "User created successfully") {
      yield put({
        type: types.SIGNUP_USER_SUCCESS,
        payload: {},
      });

      history.push("/");
      NotificationManager.success(resData.success);
    } else {
      yield put({
        type: types.SIGNUP_USER_FAILURE,
        payload: { error: "signUp failed " },
      });
      NotificationManager.error(resData.error);
    }
  } catch (err) {
    if (err.response && err.response.data) {
      yield put({
        type: types.SIGNUP_USER_FAILURE,
        payload: { error: err.response.data.message },
      });
      NotificationManager.error(err.response.data.message);
    } else {
      yield put({
        type: types.SIGNUP_USER_FAILURE,
        payload: { error: "SignUp failed" },
      });
      NotificationManager.error("SignUp failed");
    }
  }
}

export function* logoutSaga() {
  try {
    const resData = yield call(api.doLogout);

    if (resData[0].Success === "1") {
      yield put({ type: types.LOGOUT_USER_SUCCESS, payload: null });

      history.push("/");
    } else {
      yield put({
        type: types.LOGIN_USER_FAILURE,
        payload: { error: "logout failed" },
      });
    }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      yield put({ type: types.REFRESH_TOKEN_REQUEST, payload: {} });
      // yield call(delay, 3000);
      // yield put({ type: types.LOGOUT_USER_REQUEST, payload: {} });

      const { success } = yield race({
        success: take(types.REFRESH_TOKEN_SUCCESS),
        fail: take(types.REFRESH_TOKEN_FAILURE),
      });

      if (success) {
        yield put({ type: types.LOGOUT_USER_REQUEST, payload: {} });
      }
    } else {
      console.log("LOGOUT FAILED");
      // yield put({
      //   type: types.LOGOUT_USER_FAILURE,
      //   payload: JSON.stringify(err)
      // });
    }
  }
}

export function* getReadingsSaga() {
  try {
    const { device } = getStore().getState().profileReducer;

    const params = {
      method: "getReading",
      device: device,
    };

    const resData = yield call(api.doGetReadings, params);

    if (resData.result && resData.result.status === 200) {
      const Data = resData.result.payload;
      yield put({ type: types.GET_READINGS_SUCCESS, payload: { Data } });
      yield put({ type: types.DEVICE_STATUS_CONNECTED });
    } else {
      yield put({
        type: types.GET_READINGS_FAILURE,
        payload: { error: "READINGS FAILED" },
      });
      yield put({
        type: types.CHECK_DEVICE_STATUS,
        payload: null,
      });
    }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      yield put({ type: types.REFRESH_TOKEN_REQUEST, payload: {} });
      const { success } = yield race({
        success: take(types.REFRESH_TOKEN_SUCCESS),
        fail: take(types.REFRESH_TOKEN_FAILURE),
      });

      if (success) {
        yield put({ type: types.GET_READINGS_REQUEST, payload: {} });
      } else {
        yield put({ type: types.LOGOUT_USER_REQUEST, payload: {} });
      }
    } else {
      yield put({
        type: types.GET_READINGS_FAILURE,
        payload: JSON.stringify(err),
      });
    }
  }
}

export function* updateProfileSaga({
  payload: { name, address, motorHorsePower, email, deviceID },
}) {
  const params = {
    name: name,
    motorhorsepower: motorHorsePower,
    email: email,
    address: address,
    deviceid: deviceID,
  };
  if (name === "") {
    yield put({ type: types.UPDATE_PROFILE_FAILURE, payload: { error: null } });
    return;
  }
  try {
    const resData = yield call(api.doUpdateProfile, params);

    if (resData[0].success === "User profile updated successfully") {
      yield put({
        type: types.UPDATE_PROFILE_SUCCESS,
        payload: { name, address, motorHorsePower, email, deviceID },
      });
      NotificationManager.success("Profile updated successfully!");
      // history.push('/user');
    } else {
      yield put({
        type: types.UPDATE_PROFILE_FAILURE,
        payload: { error: "UPDATE failed " },
      });
      NotificationManager.error("Update profile failed!");
    }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      yield put({ type: types.REFRESH_TOKEN_REQUEST, payload: {} });

      const { success } = yield race({
        success: take(types.REFRESH_TOKEN_SUCCESS),
        fail: take(types.REFRESH_TOKEN_FAILURE),
      });

      if (success) {
        yield put({ type: types.UPDATE_PROFILE_REQUEST, payload: {} });
      } else {
        NotificationManager.error("Update profile failed!");
      }
    } else {
      yield put({
        type: types.UPDATE_PROFILE_FAILURE,
        payload: { error: err.response.data.message },
      });
      NotificationManager.error("Update profile failed!");
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(types.REFRESH_TOKEN_REQUEST, refreshTokenSaga),
    takeEvery(types.LOGIN_USER_REQUEST, loginUserSaga),
    takeEvery(types.FORGOT_PASSWORD_REQUEST, forgotPasswordSaga),
    takeEvery(types.RESET_PASSWORD_REQUEST, resetPasswordSaga),
    takeEvery(types.SAVE_SETTINGS_REQUEST, saveSettingsSaga),
    takeEvery(types.START_MOTOR, startMotorSaga),
    takeEvery(types.STOP_MOTOR, stopMotorSaga),
    takeEvery(types.USER_PROFILE_REQUEST, userProfileSaga),
    takeEvery(types.CHECK_DEVICE_STATUS, deviceStatusSaga),
    takeEvery(types.SIGNUP_USER_REQUEST, signUpSaga),
    takeEvery(types.LOGOUT_USER_REQUEST, logoutSaga),

    takeEvery(types.GET_READINGS_REQUEST, getReadingsSaga),

    takeEvery(types.UPDATE_PROFILE_REQUEST, updateProfileSaga),
  ]);
}
