export const RESET_LOGIN = "RESET_LOGIN";
export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";
export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE";
export const START_MOTOR = "START_MOTOR";
export const START_MOTOR_SUCCESS = "START_MOTOR_SUCCESS";
export const START_MOTOR_FAILURE = "START_MOTOR_FAILURE";
export const STOP_MOTOR = "STOP_MOTOR";
export const STOP_MOTOR_SUCCESS = "STOP_MOTOR_SUCCESS";
export const STOP_MOTOR_FAILURE = "STOP_MOTOR_FAILURE";
export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAILURE = "USER_PROFILE_FAILURE";
export const DEVICE_STATUS_SUCCESS = "DEVICE_STATUS_SUCCESS";
export const DEVICE_STATUS_FAILURE = "DEVICE_STATUS_FAILURE";
export const CHECK_DEVICE_STATUS = "CHECK_DEVICE_STATUS";
export const DEVICE_STATUS_CONNECTED = "DEVICE_STATUS_CONNECTED";
export const DEVICE_STATUS_DISCONNECTED = "DEVICE_STATUS_DISCONNECTED";
export const SIGNUP_USER_REQUEST = "SIGNUP_USER_REQUEST";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FAILURE = "SIGNUP_USER_FAILURE";
export const GET_READINGS_REQUEST = "GET_READINGS_REQUEST";
export const GET_READINGS_SUCCESS = "GET_READINGS_SUCCESS";
export const GET_READINGS_FAILURE = "GET_READINGS_FAILURE";
export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const SAVE_SETTINGS_REQUEST = "SAVE_SETTINGS_REQUEST";
export const SAVE_SETTINGS_SUCCESS = "SAVE_SETTINGS_SUCCESS";
export const SAVE_SETTINGS_FAILURE = "SAVE_SETTINGS_FAILURE";
export const RESET_READING = "RESET_READING";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
