import { handleActions } from "redux-actions";

const defaultState = {
  setting: 0,
  startTime: 0,
  stopTime: 0,
  loading: false,
};

export default handleActions(
  {
    SAVE_SETTINGS_REQUEST: (state) => ({ ...state, loading: true }),

    SAVE_SETTINGS_FAILURE: (state) => ({ ...state, loading: false }),

    SAVE_SETTINGS_SUCCESS: (state, { payload: { setting, startTime, stopTime } }) => ({
      ...state,
      setting,
      startTime,
      stopTime,
      loading: false,
    }),
  },
  defaultState
);
