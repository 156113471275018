import axios from "axios";

axios.defaults.timeout = 60000;

// login user
export const doLogin = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`https://smartstarter.azurewebsites.net/api/user/login`, params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// save settings

export const doSaveSettings = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `https://smartstarter.azurewebsites.net/api/iot/autoStartSetting`,
        params
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// start motor

export const doStartMotor = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`https://smartstarter.azurewebsites.net/api/iot/control`, params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// stop motor

export const doStopMotor = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`https://smartstarter.azurewebsites.net/api/iot/control`, params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// refresh token

export const doRefreshToken = (token) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`https://smartstarter.azurewebsites.net/api/user/token`, { token })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// request user profile
export const doUserProfile = () => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`https://smartstarter.azurewebsites.net/api/user/profile`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// check device status

export const doDeviceStatus = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`https://smartstarter.azurewebsites.net/api/iot/status`, params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// SIGNuP
export const doSignUp = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`https://smartstarter.azurewebsites.net/api/user/signup`, params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// LOGOUT

export const doLogout = () => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`https://smartstarter.azurewebsites.net/api/user/logout`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// READING
export const doGetReadings = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`https://smartstarter.azurewebsites.net/api/iot/control`, params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// update Profile
export const doUpdateProfile = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `https://smartstarter.azurewebsites.net/api/user/updateprofile`,
        params
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doForgotPassword = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`https://smartstarter.azurewebsites.net/api/user/forget`, params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doResetPassword = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`https://smartstarter.azurewebsites.net/api/user/reset`, params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
