import * as types from "./actionTypes";

export const resetLogin = () => ({
  type: types.RESET_LOGIN,
  payload: null,
});

export const login = (MobileNumber, password) => ({
  type: types.LOGIN_USER_REQUEST,
  payload: { MobileNumber, password },
});

export const logout = () => ({
  type: types.LOGOUT_USER_REQUEST,
  payload: null,
});

export const startMotor = () => ({
  type: types.START_MOTOR,
  payload: null,
});

export const stopMotor = () => ({
  type: types.STOP_MOTOR,
  payload: null,
});
export const getReadings = () => ({
  type: types.GET_READINGS_REQUEST,
  payload: null,
});

export const deviceStatus = () => ({
  type: types.CHECK_DEVICE_STATUS,
  payload: null,
});

export const signUp = (Name, MobileNo, password, deviceID, motorhorsePower) => ({
  type: types.SIGNUP_USER_REQUEST,
  payload: {
    Name,
    MobileNo,
    password,
    deviceID,
    motorhorsePower,
  },
});

export const updateProfile = (name, address, email, motorHorsePower, deviceID) => ({
  type: types.UPDATE_PROFILE_REQUEST,
  payload: {
    name,
    address,
    email,
    motorHorsePower,
    deviceID,
  },
});

export const saveSettings = (setting, startTime, stopTime) => ({
  type: types.SAVE_SETTINGS_REQUEST,
  payload: { setting, startTime, stopTime },
});

export const forgotPassword = (mobileNumber) => ({
  type: types.FORGOT_PASSWORD_REQUEST,
  payload: { mobileNumber },
});

export const resetPassword = (mobileNumber, password, otp) => ({
  type: types.RESET_PASSWORD_REQUEST,
  payload: { mobileNumber, password, otp },
});
