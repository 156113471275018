import { handleActions } from "redux-actions";

const defaultState = {
  v1: 0,
  c1: 0,
  v2: 0,
  c2: 0,
  v3: 0,
  c3: 0,
  loading: false,
  motorUpdating: false,
};

export default handleActions(
  {
    GET_READINGS_REQUEST: (state) => ({
      ...state,
      loading: true,
    }),

    GET_READINGS_FAILURE: (state) => ({
      ...state,
      loading: false,
    }),

    GET_READINGS_SUCCESS: (state, { payload: { Data } }) => {
      return {
        ...state,
        v1: Data.volt1,
        c1: Data.cur1,
        v2: Data.volt2,
        c2: Data.cur2,
        v3: Data.volt3,
        c3: Data.cur3,
        loading: false,
      };
    },

    START_MOTOR_SUCCESS: (state, { payload: { Data } }) => {
      return {
        ...state,
        motorUpdating: false,
        v1: Data.volt1,
        c1: Data.cur1,
        v2: Data.volt2,
        c2: Data.cur2,
        v3: Data.volt3,
        c3: Data.cur3,
      };
    },

    START_MOTOR_FAILURE: (state) => ({
      ...state,
      motorUpdating: false,
    }),

    STOP_MOTOR_SUCCESS: (state, { payload: { Data } }) => ({
      ...state,
      motorUpdating: false,
      v1: Data.volt1,
      c1: Data.cur1,
      v2: Data.volt2,
      c2: Data.cur2,
      v3: Data.volt3,
      c3: Data.cur3,
    }),

    STOP_MOTOR_FAILURE: (state) => ({
      ...state,
      motorUpdating: false,
    }),

    START_MOTOR: (state) => ({
      ...state,
      motorUpdating: true,
    }),
    STOP_MOTOR: (state) => ({
      ...state,
      motorUpdating: true,
    }),
    LOGOUT_USER_SUCCESS: () => ({
      ...defaultState,
    }),
    RESET_READING: () => ({
      ...defaultState,
    }),
  },
  defaultState
);
