import React, { useState } from "react";
import { connect } from "react-redux";
import { FaCheck, FaTimes } from "react-icons/fa";
import { BiLogOutCircle } from "react-icons/bi";

import classes from "./Settings.module.css";
import iEdit from "../../asset/edit.png";
import Navbar from "../Navbar/Navbar";
import { logout, updateProfile } from "../../store/actions/userActions";
import { NotificationManager } from "react-notifications";

function Settings(p) {
  const [edit, setEdit] = useState();
  const [name, setName] = useState(p.name || "");
  const [address, setAddress] = useState(p.address || "");
  const [email, setEmail] = useState(p.email || "");
  const [hp, setHP] = useState(p.motorHP);
  const [error, setError] = useState();

  const submit = () => {
    if (!name.trim()) {
      setError("Please enter name");
    } else if (!address.trim()) {
      setError("Please enter address");
    } else if (
      email.trim() &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email.trim()
      )
    ) {
      setError("Please enter a valid email");
    } else if (hp < 1 || hp > 10) {
      setError("Please enter a valid horsepower between 1 to 10");
    } else {
      setError();
      p.updateProfile(name.trim(), address.trim(), email.trim(), hp, p.deviceID);
      NotificationManager.success("Saving profile");
      setEdit();
    }
  };

  const cancel = () => {
    setName(p.name);
    setAddress(p.address);
    setEmail(p.email);
    setHP(p.motorHP);
    setError();
    setEdit();
  };

  return (
    <div className={classes.full}>
      <Navbar />
      <div className={classes.body}>
        {edit ? (
          <>
            <div className={`form-floating mb-3 ${classes.floating}`}>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label htmlFor="name">Name</label>
            </div>
            <div className={`form-floating mb-3 ${classes.floating}`}>
              <textarea
                className="form-control"
                id="address"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <label htmlFor="address">Address</label>
            </div>
            <div className={`form-floating mb-3 ${classes.floating}`}>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="email">Email</label>
            </div>
            <div className={`form-floating mb-3 ${classes.floating}`}>
              <input
                type="text"
                className="form-control"
                id="motorHP"
                placeholder="Motor HP"
                value={hp}
                onChange={(e) => setHP(e.target.value)}
              />
              <label htmlFor="motorHP">Motor HP</label>
            </div>
          </>
        ) : (
          <>
            <div className="row">
              <div className="col-4">Name</div>
              <div className="col-8">{p.name}</div>
            </div>
            <div className="row">
              <div className="col-4">Address</div>
              <div className="col-8">{p.address}</div>
            </div>
            <div className="row">
              <div className="col-4">Email</div>
              <div className="col-8">{p.email}</div>
            </div>
            <div className="row">
              <div className="col-4">Mobile No.</div>
              <div className="col-8">{p.mobile}</div>
            </div>
            <div className="row">
              <div className="col-4">Device ID</div>
              <div className="col-8">{p.deviceID}</div>
            </div>
            <div className="row">
              <div className="col-4">Motor Type</div>
              <div className="col-8">{p.motorType === 3 ? "Three phase" : "Single phase"}</div>
            </div>
            <div className="row">
              <div className="col-4">Motor HP</div>
              <div className="col-8">{p.motorHP}</div>
            </div>
            <div className="row">
              <div className="col-4">Version</div>
              <div className="col-8">22.09.26.1</div>
            </div>
          </>
        )}
        <div className="error">{error}</div>
      </div>
      <div className={classes.footer}>
        {edit ? (
          <>
            <div className={classes.editButton} onClick={submit}>
              <FaCheck className={classes.editIcon} />
              &nbsp;&nbsp;SUBMIT
            </div>
            <div className={classes.logoutButton} onClick={cancel}>
              <FaTimes className={classes.editIcon} />
              &nbsp;&nbsp;CANCEL
            </div>
          </>
        ) : (
          <>
            <div className={classes.editButton} onClick={() => setEdit(true)}>
              <img src={iEdit} alt="Edit" />
              &nbsp;&nbsp;EDIT
            </div>
            <div className={classes.logoutButton} onClick={p.logout}>
              <BiLogOutCircle className={classes.logoutIcon} />
              &nbsp;&nbsp;LOGOUT
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ profileReducer: p, readingsReducer: r, scheduleReducer: s }) => ({
  name: p.name,
  address: p.address,
  email: p.email,
  mobile: p.MobileNo,
  deviceID: p.device,
  motorType: p.motorType,
  motorHP: p.motorHorsePower,
});

const mapDispatchToProps = {
  logout,
  updateProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
