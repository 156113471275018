import React, { useState } from "react";
import ReactSlider from "react-slider";
import { NotificationManager } from "react-notifications";

import classes from "./Dashboard.module.css";

import BottomBar from "../BottomBar/BottomBar";

function Scheduler({ schedule: sch, startTime: start, stopTime: stop, onCancel, onOk }) {
  const [schedule, setSchedule] = useState(sch);
  const [startTime, setStartTime] = useState(start);
  const [stopTime, setStopTime] = useState(stop);

  const onScheduleOk = () => {
    let start = startTime,
      stop = stopTime;
    if (schedule !== 2) {
      start = stop = "00:00";
    }
    start = start.split(":");
    stop = stop.split(":");
    start = parseInt(start[0]) * 3600 + parseInt(start[1]) * 60;
    stop = parseInt(stop[0]) * 3600 + parseInt(stop[1]) * 60;
    onOk(schedule, start, stop);
    NotificationManager.success("Saving schedule");
    onCancel();
  };

  return (
    <BottomBar onCancel={onCancel} onOk={onScheduleOk}>
      <div className={classes.schedule}>
        <div className={classes.scheduleTitle}>Auto Start Motor</div>
        <ReactSlider
          className={classes.slider}
          min={0}
          max={2}
          value={schedule}
          thumbClassName={`${classes.thumb} ${schedule === 0 ? classes.thumbOff : ""}`}
          trackClassName={classes.track}
          onChange={setSchedule}
        />
        <div className={classes.scheduleLabels}>
          <div className={schedule === 0 ? classes.greyHighlight : ""}>Off</div>
          <div className={schedule === 1 ? classes.colorHighlight : ""}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On
          </div>
          <div className={schedule === 2 ? classes.colorHighlight : ""}>Schedule</div>
        </div>
        {schedule === 2 && (
          <div className={classes.scheduleTime}>
            <div className={classes.timer}>
              <div className={classes.timerLabel}>Start Time</div>
              <input
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.currentTarget.value)}
              />
            </div>
            <div className={classes.timer}>
              <div className={classes.timerLabel}>Stop Time</div>
              <input
                type="time"
                value={stopTime}
                onChange={(e) => setStopTime(e.currentTarget.value)}
              />
            </div>
          </div>
        )}
      </div>
    </BottomBar>
  );
}

export default Scheduler;
