import React, { Component } from "react";
import Dashboard from "./UI/Dashboard/Dashboard";
import LoginPage from "./login/LoginPage";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import AxiosConfig from "./axiosConfig";
import { history } from "./history";
import SignUpPage from "./login/SignupPage";
import ForgotPassword from "./login/ForgotPassword";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import ResetPassword from "./login/ResetPassword";
import Settings from "./UI/Settings/Settings";

class App extends Component {
  componentDidMount() {
    AxiosConfig.init();
  }

  render() {
    const PrivateRoute = ({ component: Comp, ...rest }) => {
      const { accessToken } = this.props.user;
      return (
        <Route
          {...rest}
          render={(props) => (accessToken ? <Comp {...props} /> : <Redirect to="/login" />)}
        />
      );
    };

    return (
      <Router history={history}>
        <Switch>
          <Route path="/login" exact component={LoginPage} />
          <Route path="/signUp" exact component={SignUpPage} />
          <Route path="/forgotPassword" exact component={ForgotPassword} />
          <Route path="/resetPassword" exact component={ResetPassword} />

          <PrivateRoute path="/" exact component={Dashboard} />
          <PrivateRoute path="/profile" exact component={Settings} />
        </Switch>
        <NotificationContainer />
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer,
  };
};

const AppContainer = connect(mapStateToProps, null)(App);

export default AppContainer;
